* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;

    &.no-scroll {
        overflow: hidden;
    }
    &.block {
        pointer-events: none;
    }
}

.main-body {
    &__content {
        background-color: $white;

        @include device-lg('min') {
            min-height: calc(100% - 80px);
        }
    }
}

body,
button {
    font-family: 'Nunito', sans-serif;
    @include font-size(14, 23, 400);
    color: $black;
}

input,
select,
textarea {
    font-family: 'Nunito', sans-serif;
}
.wrapper {
    display: block;
    width: 100%;
    position: relative;
    max-width: $min_width_wrapper;
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;

    &.--wide {
        max-width: $max_width_wrapper;
    }

    @include device-lg {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include device-md {
        padding-left: 16px;
        padding-right: 16px;
    }
}

section {
    padding-top: 0px;
    padding-bottom: 0px;
}

strong {
    font-weight: 700;
}

a {
    text-decoration: underline;
    color: inherit;

    &:hover {
        color: $green;
    }

    &[data-gtm-action='cta'] > *:not(a):not(button) {
        pointer-events: none; // Evita problemas de trackeo en GTM
    }
}

a,
button {
    &[data-gtm-action='cta'] > *:not(a):not(button) {
        pointer-events: none; // Evita problemas de trackeo en GTM
    }
}
