@font-face {
    font-family: "Nunito";
    font-weight: 100;
    font-display: auto;
    src: url("../fonts/Nunito/Nunito-ExtraLight.woff2") format("woff2"),
        url("../fonts/Nunito/Nunito-ExtraLight.woff") format("woff");
}

@font-face {
    font-family: "Nunito";
    font-weight: 300;
    font-display: auto;
    src: url("../fonts/Nunito/Nunito-Light.woff2") format("woff2"),
        url("../fonts/Nunito/Nunito-Light.woff") format("woff");
}

@font-face {
    font-family: "Nunito";
    font-weight: 400;
    font-display: auto;
    src: url("../fonts/Nunito/Nunito-Regular.woff2") format("woff2"),
        url("../fonts/Nunito/Nunito-Regular.woff") format("woff");
}

@font-face {
    font-family: "Nunito";
    font-weight: 500;
    font-display: auto;
    src: url("../fonts/Nunito/Nunito-SemiBold.woff2") format("woff2"),
        url("../fonts/Nunito/Nunito-SemiBold.woff") format("woff");
}
@font-face {
    font-family: "Nunito";
    font-weight: 700;
    font-display: auto;
    src: url("../fonts/Nunito/Nunito-Bold.woff2") format("woff2"),
        url("../fonts/Nunito/Nunito-Bold.woff") format("woff");
}
@font-face {
    font-family: "Nunito";
    font-weight: 900;
    font-display: auto;
    src: url("../fonts/Nunito/Nunito-Black.woff2") format("woff2"),
        url("../fonts/Nunito/Nunito-Black.woff") format("woff");
}