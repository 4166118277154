/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: transparent;
}

@font-face {
  font-family: "Nunito";
  font-weight: 100;
  font-display: auto;
  src: url("../fonts/Nunito/Nunito-ExtraLight.woff2") format("woff2"), url("../fonts/Nunito/Nunito-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Nunito";
  font-weight: 300;
  font-display: auto;
  src: url("../fonts/Nunito/Nunito-Light.woff2") format("woff2"), url("../fonts/Nunito/Nunito-Light.woff") format("woff");
}
@font-face {
  font-family: "Nunito";
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/Nunito/Nunito-Regular.woff2") format("woff2"), url("../fonts/Nunito/Nunito-Regular.woff") format("woff");
}
@font-face {
  font-family: "Nunito";
  font-weight: 500;
  font-display: auto;
  src: url("../fonts/Nunito/Nunito-SemiBold.woff2") format("woff2"), url("../fonts/Nunito/Nunito-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Nunito";
  font-weight: 700;
  font-display: auto;
  src: url("../fonts/Nunito/Nunito-Bold.woff2") format("woff2"), url("../fonts/Nunito/Nunito-Bold.woff") format("woff");
}
@font-face {
  font-family: "Nunito";
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/Nunito/Nunito-Black.woff2") format("woff2"), url("../fonts/Nunito/Nunito-Black.woff") format("woff");
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}
body.no-scroll,
html.no-scroll {
  overflow: hidden;
}
body.block,
html.block {
  pointer-events: none;
}

.main-body__content {
  background-color: #ffffff;
}
@media (min-width: 1024px) {
  .main-body__content {
    min-height: calc(100% - 80px);
  }
}

body,
button {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6428571429;
  letter-spacing: normal;
  color: #2d2d2d;
}

input,
select,
textarea {
  font-family: "Nunito", sans-serif;
}

.wrapper {
  display: block;
  width: 100%;
  position: relative;
  max-width: 1272px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}
.wrapper.--wide {
  max-width: 1440px;
}
@media (max-width: 1023px) {
  .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 719px) {
  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

section {
  padding-top: 0px;
  padding-bottom: 0px;
}

strong {
  font-weight: 700;
}

a {
  text-decoration: underline;
  color: inherit;
}
a:hover {
  color: #008657;
}
a[data-gtm-action=cta] > *:not(a):not(button) {
  pointer-events: none;
}

a[data-gtm-action=cta] > *:not(a):not(button),
button[data-gtm-action=cta] > *:not(a):not(button) {
  pointer-events: none;
}