///////////////////////
// Breakpoints
$screen-sm-min: 576; // Small tablets and large smartphones (landscape view)
$screen-md-min: 720; // Small tablets (portrait view)
$screen-lg-min: 1024; // Tablets and small desktops
$screen-xl-min: 1440; // Large tablets and desktops

$max_width_wrapper: 1440px;
$min_width_wrapper: 1272px;
$timming: 0.2s;

// Primary colors
$green: #008657;
$green-light: #d7e7e1;
$orange: #f7931d;

$white: #ffffff;
$light-gray: #f1f1f1;
$medium-gray: #e7e7e7;
$dark-gray: #6f6e6f;
$black: #2d2d2d;

//Secondary colors
$light-green-50: #ebf8f3;
$light-green-100: #ecf5f2;

$light-gray-25: #fafafa;

$success-50: #ecfdf3;
$success: #12b76a;
$success-700: #027a48;

$danger-50: #fef3f2;
$danger: #f04438;
$danger-700: #7a271a;

// Fonts
$main-font: "Nunito", sans-serif;

// Grid Columns "Gaps"
$gg-sm: 12px;
$gg-md: 20px;
$gg-lg: 30px;
